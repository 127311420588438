
import Vue, { PropOptions } from "vue";
import tag from "~/components/data-display/tag.vue";
import tags from "~/components/data-display/tags.vue";

export default Vue.extend({
  components: {
    tag: tag,
    tags: tags,
  },
  props: {
    items: {
      type: Object,
      required: false,
    } as PropOptions<any[]>,
    itemClass: {
      type: String,
      required: false,
    } as PropOptions<String>,
    limit: {
      type: Number,
      required: false,
    } as PropOptions<Number>,
    filtering: {
      type: Boolean,
      required: false,
    } as PropOptions<Boolean>,
  },
  computed: {
    filters(): any {
      return this.$store.state.filters.filter;
    },
  },
  data() {
    let allFilters: any = {};

    //create unique list of tags on items to filter
    function forEach(array: any[], callback: Function) {
      for (let index = 0; index < array.length; index++) {
        callback(array[index], index, array);
      }
    }

    forEach(this.$props.items.data, async (item: { attributes: { Tags: string } }): Promise<any> => {
      if (item.attributes.hasOwnProperty("Tags") && item.attributes.Tags !== null) {
        allFilters[item.attributes.Tags] = item.attributes.Tags.toString();
      }
    });
    const filterArray = Object.keys(allFilters).map((key) => allFilters[key]);

    const sortedArray = filterArray.sort().reverse();
    return { sortedArray };
  },
  methods: {
    setFilter(e: any) {
      let elems = document.querySelectorAll(".filters .btn-filter");

      this.$store.commit("filters/add", e.target.dataset.filter);
      e.target.value = "";
    },
    shouldDisplay(e: any) {
      if (this.$store.state.filters.filter === "all") {
        return true;
      }
      if (e.attributes.Tags instanceof Array) {
        return false;
      }
      return this.$store.state.filters.filter === e.attributes.Tags;
    },
  },
});
